import React, { useEffect, useCallback, useMemo, Dispatch, useState } from 'react';
import { TextFormField } from '../forms/textField/textField';
import { FormattedTextFormField } from '../forms/textField/formattedTextField';
import { FormRow, BtnRow, BtnForm, VoucherInput } from '../forms/Forms';
import { CheckBoxFormField } from '../forms/checkBox/checkBox';
import { Address } from 'src/api';
import { isEqual, fromPairs } from 'lodash';
import ErrorList from '../forms/errorList/errorList';
import { PostalFormat, NIPFormat } from '../admin/input';
import { useFormikContext, FormikErrors, FormikTouched } from 'formik';
import { getDeliveryHours } from 'src/logic/addresses';
import { PostalCode, orderActions } from 'src/logic/order';

export type AddressType = 'home' | 'office' | 'invoice' | 'not-company-invoice';

export const emptyHomeAddress: Address = {
  type: 'home',
  city: '',
  deliveryTo7: false,
  deliveryTo6: false,
  vatin: '',
  name: '',
  street: '',
  house: '',
  local: '',
  postalcode: '',
  additional: '',
};
export const emptyOfficeAddress: Address = {
  type: 'office',
  city: '',
  deliveryTo7: false,
  deliveryTo6: false,
  vatin: '',
  name: '',
  street: '',
  house: '',
  local: '',
  postalcode: '',
  additional: '',
};
export const emptyInvoiceAddress: Address = {
  type: 'invoice',
  city: '',
  deliveryTo7: false,
  deliveryTo6: false,
  vatin: '',
  name: '',
  street: '',
  house: '',
  local: '',
  postalcode: '',
  additional: '',
};

type AddressFormProps = {
  parent?: string;
  address: Address;
  saveAddress?: boolean;
  changeAddress?: (
    fn: (a: Address) => Address,
    addressType: AddressType
  ) => unknown;
  addCode?: (code: string) => unknown;
  addressType: AddressType;
};

const validateAddressField = (dispatch?: Dispatch<any>, postalCodes?: PostalCode[]) => <T extends any>(
  value: T,
  name: keyof Address,
  addressType: AddressType
  ): string => {

  const isValidationPostalCodeRequired = !!postalCodes && !!dispatch;

  const checkingPostalCode = (postalCode: string) => {
    const savedPostalCode = postalCodes?.find((code) => code.postalCode === postalCode)
    if (!savedPostalCode) {
      dispatch && dispatch(orderActions.checkPostalCode.request(postalCode));
    }
  }

  if (typeof value !== 'string') return '';
  switch (name) {
    case 'name':
      return (addressType === 'invoice' || addressType === 'office') && value.length === 0
        ? 'Nazwa firmy jest wymagana'
        : '';
    case 'city':
      return value.length === 0 ? 'Miasto jest wymagane' : '';
    case 'postalcode':
      value.length === 6 && isValidationPostalCodeRequired && checkingPostalCode(value);
      return value.length !== 6
        ? 'Nieprawidłowy kod pocztowy'
        : !isValidationPostalCodeRequired
          ? ''
          : !postalCodes?.find(code => code.postalCode === value)?.isAvailable
            ? 'Niestety, wybrany adres jest poza obszarem dostaw'
            : '';
    case 'vatin':
      return addressType === 'invoice' && value.length !== 13
        ? 'Nieprawidłowy NIP'
        : '';
    case 'street':
      return value.length === 0 ? 'Ulica jest wymagana' : '';
    default:
      return '';
  }
};

export const validateAddress = (dispatch?: Dispatch<any>, postalCodes?: PostalCode[]) => (
  address?: Address
): FormikErrors<Address> => {
  const res = Object.entries(address ?? {})
    .map(([key, value]) => [
      key,
      validateAddressField(dispatch, postalCodes)(
        value,
        key as keyof Address,
        (address?.type ?? 'home') as AddressType
      ),
    ])
    .filter(([, v]) => v);
  return fromPairs(res);
};

type NestedAddress = { [s: string]: Address };
export const AddressForm = ({
  parent,
  address,
  saveAddress,
  addressType,
  addCode,
}: AddressFormProps) => {
  const { values, errors, touched, setValues, handleSubmit, setFieldValue } = useFormikContext<
    NestedAddress | Address
  >();

  useEffect(() => {
    if (saveAddress) {
      setValues(parent ? { [parent]: address  } : address);
    }
  }, [parent, address, setValues, saveAddress]);

  useEffect(() => {
    setFieldValue(parent ? `${parent}.type` : 'type', addressType)
  }, [addressType]);

  const errorsList = useMemo(() => {
    const touchedValues = parent
      ? (touched as FormikTouched<NestedAddress>)[parent] ?? {}
      : (touched as FormikTouched<Address>);
    const errorValues = parent
      ? (errors as FormikErrors<NestedAddress>)[parent] ?? {}
      : (errors as FormikErrors<Address>);
    return Object.entries(touchedValues)
      .filter(([, value]) => value)
      .map(([key]) => errorValues[key as keyof Address])
      .filter((e): e is string => !!e)
      .map((e) => `${e}`);
  }, [parent, errors, touched]);

  const handleCancel = useCallback(() => {
    setValues(parent ? { [parent]: { ...address } } : { ...address });
  }, [setValues, parent, address]);
  const changed = !isEqual(values, address);


  return (
    <>
      {addressType === 'office' && (
        <FormRow>
          <label className="uppercase relative">
            Posiadasz kod promocyjny?<br />
          </label>
          <VoucherInput addCode={addCode} />
        </FormRow>
      )}
      {(addressType === 'invoice' || addressType === 'office') && (
        <FormRow>
          <TextFormField<keyof Address>
            name="name"
            parent={parent}
            label="Nazwa firmy"
          />
          {addressType === 'invoice' && (
            <FormattedTextFormField<keyof Address>
              name="vatin"
              parent={parent}
              label="NIP"
              format={NIPFormat}
            />
          )}
        </FormRow>
      )}
      <FormRow>
        <TextFormField<keyof Address>
          name="street"
          parent={parent}
          label="Ulica"
        />
        <FormRow>
          <TextFormField<keyof Address>
            name="house"
            parent={parent}
            label="Nr budynku"
          />
          <TextFormField<keyof Address>
            name="local"
            parent={parent}
            label="Nr lokalu"
          />
        </FormRow>
      </FormRow>
      <FormRow>
        <FormattedTextFormField<keyof Address>
          name="postalcode"
          parent={parent}
          label="Kod pocztowy"
          format={PostalFormat}
        />
        <TextFormField<keyof Address>
          name="city"
          parent={parent}
          label="Miasto"
        />
      </FormRow>
      {(addressType !== 'invoice' && addressType !== 'not-company-invoice') && (
        <FormRow>
          <TextFormField<keyof Address>
            name="additional"
            parent={parent}
            label="Informacje dla kuriera (klatka, piętro, kod wejścia, dodatkowe wytyczne)"
          />
        </FormRow>
      )}
      {(addressType !== 'invoice' && addressType !== 'not-company-invoice') && (
        <p className='whiteSpace'>
          <b>{getDeliveryHours()}</b>
        </p>
      )}
      {
        (addressType === 'home' || addressType === 'office') &&
        (
          <FormRow>
            <CheckBoxFormField<keyof Address> name="deliveryTo6" parent={parent} onChange={(v) => v && setFieldValue(parent ? `${parent}.deliveryTo7` : 'deliveryTo7', false)}>
              PROSZĘ O DOSTAWĘ DO GODZINY 06:00
            </CheckBoxFormField>
            <CheckBoxFormField<keyof Address> name="deliveryTo7" parent={parent}  onChange={(v) => v && setFieldValue(parent ? `${parent}.deliveryTo6` : 'deliveryTo6', false)}>
              PROSZĘ O DOSTAWĘ DO GODZINY 07:00
            </CheckBoxFormField>
          </FormRow>
        )
      }
      {saveAddress && (
        <BtnRow>
          <BtnForm type="black" disabled={!changed} action={handleSubmit}>
            Zapisz zmiany
          </BtnForm>
          <BtnForm type="red" disabled={!changed} action={handleCancel}>
            Anuluj zmiany
          </BtnForm>
        </BtnRow>
      )}
      <ErrorList errors={errorsList} />
    </>
  );
};
